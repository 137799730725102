<script setup lang="ts">
import { ExclamationCircleIcon, XCircleIcon, CheckCircleIcon, InformationCircleIcon } from '@heroicons/vue/24/outline'
import { XMarkIcon } from '@heroicons/vue/20/solid'

export type NotificationType = 'success' | 'error' | 'warning' | 'info'


const props = defineProps<{
    title: string,
    subtitle: string,
    show: boolean,
    notificationType: NotificationType,
    persist?: boolean
}>()

const emit = defineEmits(['update:show'])

watch(() => props.show, (show) => {
    if (show && !props.persist) {
        setTimeout(() => {
            emit('update:show', false)
        }, 5000)
    }
})


</script>

<template>
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div
        aria-live="assertive"
        class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-30"
    >
        <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
            <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
            <transition
                enter-active-class="transform ease-out duration-300 transition"
                enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div
                    v-if="show"
                    class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                >
                    <div class="p-4">
                        <div class="flex items-start">
                            <div class="shrink-0">
                                <CheckCircleIcon
                                    v-if="notificationType === 'success'"
                                    class="size-6 text-green-400"
                                    aria-hidden="true"
                                />
                                <XCircleIcon
                                    v-else-if="notificationType === 'error'"
                                    class="size-5 text-red-400"
                                    aria-hidden="true" />
                                <ExclamationCircleIcon
                                    v-else-if="notificationType === 'warning'"
                                    class="size-5 text-yellow-400"
                                    aria-hidden="true" />
                                <InformationCircleIcon
                                    v-else
                                    class="size-5 text-blue-400"
                                    aria-hidden="true"  />
                            </div>

                            <div class="ml-3 w-0 flex-1 pt-0.5">
                                <p class="text-sm font-medium text-gray-900">
                                    {{ title }}
                                </p>

                                <p class="mt-1 text-sm text-gray-500">
                                    {{ subtitle }}
                                </p>
                            </div>

                            <div class="ml-4 flex shrink-0">
                                <button
                                    type="button"
                                    class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                    @click="emit('update:show', false)"
                                >
                                    <span class="sr-only">Close</span>

                                    <XMarkIcon
                                        class="size-5"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
